
/* Page with just the login button */

/* Path: src/App.css */

body {
    text-align:  center;
    /* background gradient gray with white */ 

    background: linear-gradient(90deg, rgba(52,54,59,1) 0%, rgba(49,51,56,1) 35%, rgba(35,37,41,1) 100%); 
    color:  white;

    display:  flex;
    flex-direction:  column;
    align-items:  center;
    justify-content:  center;
    height:  100vh;
}

button {
    border:  none;
    color:  white;
    padding:  15px 32px;
    text-align:  center;
    text-decoration:  none;
    display:  inline-block;
    font-size:  16px;
    margin:  4px 2px;
    cursor:  pointer;
    background-color:  #3f4aee;
    /* rounded corners */
    border-radius:  15px;
}

